import img1 from '../../../assets/img/blog/1.png'
import img2 from '../../../assets/img/blog/2.png'
import img3 from '../../../assets/img/blog/3.png'
import author1 from '../../../assets/img/blog/author.png'
 
export const BlogData = [
 {
  img:img1,
  heading:"Content strategy can help engage your customers",
  date:"23",
  month:"May",
  para:" There are many variations of Lorem um passages of Lorem Ipsum",
  author_img:author1,
  authorName:"John Doe",
  category:"Business"
 },
 {
  img:img2, 
  heading:"Content strategy can help engage your customers",
  date:"13",
  month:"Jun",
  para:" There are many variations of Lorem um passages of Lorem Ipsum",
  author_img:author1,
  authorName:"John Doe",
  category:"Business"
 },
 {
  img:img3,
  heading:"Content strategy can help engage your customers",
  date:"31",
  month:"July",
  para:" There are many variations of Lorem um passages of Lorem Ipsum",
  author_img:author1,
  authorName:"John Doe",
  category:"Business"
 }
];
