import React from 'react'
import FaqsArea from '../Faqs/FaqsArea'

const AllFaqs = () => {
    return (
        <>
            <FaqsArea/>
        </>
    )
}

export default AllFaqs
