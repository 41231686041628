import Icon1 from '../../../assets/img/service/1.png'
import Icon2 from '../../../assets/img/service/2.png'
import Icon3 from '../../../assets/img/service/3.png'
import Icon4 from '../../../assets/img/service/4.png'
import Icon5 from '../../../assets/img/service/5.png'
import Icon6 from '../../../assets/img/service/6.png'

export const OurServiceData = [
{
 img:Icon1,
 heading:"Data Extraction/ETL",
 paragraph:"We can build custom data ETL pipelines. Pipelines that can process data in batch or stream.",
 button:""
},
{
 img:Icon2,
 heading:" Data Science & Analytics ",
 paragraph:"Data clean up and feature extraction.",
 button:""
},
{
 img:Icon3,
 heading:"Ecommerce Development ",
 paragraph:"Developing and maintaining e-commerce sites. payment gateway integrations, building reporting tools.",
 button:""
},
{
 img:Icon4,
 heading:"Scripts & Utilities ",
 paragraph:"We can write simple maintainable scripts to automate your routine grunt work so you can spend your valuable time on high priority work.",
 button:""
},
{
 img:Icon5,
 heading:"Web Development ",
 paragraph:"Build and maintain a complete SaaS platform end-to-end.",
 button:""
},
{
 img:Icon6,
 heading:"Business Intelligence",
 paragraph:"Encompasses data mining, process analysis, performance benchmarking, and descriptive analytics.",
 button:""
}
]
