import img1 from '../../../assets/img/testimonial/1.png'
import img2 from '../../../assets/img/testimonial/2.png'

export const TestData = [
    {
        para: "Amazing working Raj again, very professional and gets the job done!",
        img: img1,
        name: "Axion",
        des: "CEO, ABC Company"
    },
    {
        para: "Another successful project with Raj. He is a talented engineer and it is always a pleasure to work together.",
        img: img2,
        name: "Labrador AI",
        des: "CEO, CDR Company"
    },
    {
        para: "Another great project with Raj. Look forward to the next one!",
        img: img1,
        name: "Pipecandy",
        des: "Manager, HL Company"
    },
    {
        para: "Murali was great to work with. He listened to what I needed and send me exactly what I had asked for. And, he did the job very quickly. Will definitely go to him first for any design needs I have in the future. Thanks Murali! I really enjoyed working with you.",
        img: img2,
        name: "James",
        des: "Upwork"
    },
    {
        para: "Excellent work. I have already hired him for a second project. I highly recomment him.",
        img: img1,
        name: "Amelia",
        des: "Upwork"
    },
    {
        para: "excellent job done.",
        img: img2,
        name: "Jessica",
        des: "CEO, MAL Company"
    },
]