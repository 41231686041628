import img1 from '../../assets/img/shop/p-1.png'


export const CartData = [
    {
        img: img1,
        name: "Handbag Fringilla",
        price: 65.00,
        total: 130.00,
        quantity: "Quantity"
    },
    {
        img: img1,
        name: "Handbag Fringilla",
        price: 65.00,
        total: 130.00,
        quantity: "Quantity"
    },
    {
        img: img1,
        name: "Handbag Fringilla",
        price: 65.00,
        total: 130.00,
        quantity: "Quantity"
    }
]

