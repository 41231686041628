import React from 'react'
import ResetPasswordForm from '../component/ResetPassword'

const ResetPassword = () => {
    return (
        <>
            <ResetPasswordForm/>
        </>
    )
}

export default ResetPassword
