import React from 'react'
import RegisterForm from '../component/Register'

const Register = () => {
    return (
        <>
            <RegisterForm/>
        </>
    )
}

export default Register
