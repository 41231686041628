import logo1 from '../../../assets/img/logo/axion.png'
import logo2 from '../../../assets/img/logo/pipecandy.png'
import logo3 from '../../../assets/img/logo/labrador.png'


export const PartnerData =[
 {
  img:logo1
 },
 {
  img:logo2
 },
 {
  img:logo3
 },
 ];

