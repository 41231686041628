
import img1 from '../../assets/img/shop/1.png'


export const ProductSliderData = [
    {
        img: img1
    },
    {
        img: img1
    },
    {
        img: img1
    }
]
