import img from '../../../assets/img/client/clients_img_3.jpg'
export const ClientsData = [
    {
        para:`Grursus mal suada faci lisis Lorem ipsum dolarorit more and dumm ametion consectetur elit.
        Vesti at bulum nec odio area`,
        img:img,
        name:"Tom Alex",
        des:"Software Developer"
    },
    {
        para:`Grursus mal suada faci lisis Lorem ipsum dolarorit more and dumm ametion consectetur elit.
        Vesti at bulum nec odio area`,
        img:img,
        name:"Tom Alex",
        des:"Software Developer"
    },
    {
        para:`Grursus mal suada faci lisis Lorem ipsum dolarorit more and dumm ametion consectetur elit.
        Vesti at bulum nec odio area`,
        img:img,
        name:"Tom Alex",
        des:"Software Developer"
    },
    {
        para:`Grursus mal suada faci lisis Lorem ipsum dolarorit more and dumm ametion consectetur elit.
        Vesti at bulum nec odio area`,
        img:img,
        name:"Tom Alex",
        des:"Software Developer"
    },
    {
        para:`Grursus mal suada faci lisis Lorem ipsum dolarorit more and dumm ametion consectetur elit.
        Vesti at bulum nec odio area`,
        img:img,
        name:"Tom Alex",
        des:"Software Developer"
    },
]