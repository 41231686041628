export const PricingData=[
{
 title:"Standard Package",
 price:"70",
 data:[
  {
   list:"Unlimited Campaigns"
  },
  {
   list:"Basic Donor Data"
  },
  {

   list:"Team Fundraising"
  },
  {
   list:"Weekly Campaigns"
  },
  {
   list:"Data Analysis"
  },
  {
   list:"Data Analytics"
  },
  {
   list:"Daily Report Delivery"
  },
  {
   list:"Business Prediction"
  },
 ],
btn:"BUY NOW"
},
{
 title:"Standard Package",
 price:"40",
 data:[
  {
   list:"Unlimited Campaigns"
  },
  {
   list:"Basic Donor Data"
  },
  {
   list:"Team Fundraising"
  },
  {
   list:"Weekly Campaigns"
  },
  {
   list:"Data Analysis"
  },
  {
   list:"Data Analytics"
  },
  {
   list:"Daily Report Delivery"
  },
  {
   list:"Business Prediction"
  },
 ],
btn:"BUY NOW"
},
{
 title:"Standard Package",
 price:"20",
 data:[
  {
   list:"Unlimited Campaigns"
  },
  {

   list:"Basic Donor Data"
  },
  {
   list:"Team Fundraising"
  },
  {
   list:"Weekly Campaigns"
  },
  {
   list:"Data Analysis"
  },
  {
   list:"Data Analytics"
  },
  {
   list:"Daily Report Delivery"
  },
  {
   list:"Business Prediction"
  },
 ],
btn:"BUY NOW"
}
]