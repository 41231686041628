 import React from 'react'

//  Import Img
 import check from '../../../assets/img/about/tick.png'
 import AboutImg from '../../../assets/img/about/1.png'

 import { Link } from 'react-router-dom';
 
 const AboutOne=()=> {
  return (
   <>
   {/* About One Area Start */}
     <section id="about" className="py100">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="left-side-title">
                        <h3>About Company</h3>
                        {/* <h2 className="pt10">
                            We Provide Real Time Data Solutions, Analysis, Analytics And Prediction
                        </h2> */}
                    </div>
                    <div className="about-details pt10">
                        <p>We are a group of freelance experts from different fields joined with the idea of providing world-class service with great client experience.</p>
                        <p>We provide custom data engineering solutions, build custom tools for data clean up and analysis and help derive insights from data.</p>
                        <p>Our full-stack developers reduce the need for separate backend and frontend developers.</p>
                        <p>We have experience in building and maintaining a complete SaaS platform end-to-end and We have turned complex ideas into an MVP in a short period of time.</p>
                        {/* <ul className="pt20">
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  />We are a group of freelance experts from different fields joined with the idea of providing world-class service with great client experience. 
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  />  
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  /> 
                            </li>
                            <li className="wow zoomIn" data-wow-duration="2.0s">
                                <img alt="" src={check}  /> 
                            </li>
                        </ul> */}
                        {/* <Link to="/#" className="btn btn-theme mt30">Hire Us</Link> */}
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="animation-img animation-img-one">
                        <img alt="" src={AboutImg}  />
                    </div>
                </div>
            </div>
        </div>
    </section>
       {/* About One Area End */}
   </>

  )
 }
 
 export default AboutOne
 